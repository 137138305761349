<template>
	<div class="grid layout-help-page">
		<div class="col-12">
			<div class="card p-0">
				<div class="grid grid-nogutter">
					<div class="col-12 layout-help-page-header">
						<img src="layout/images/extensions/help-header@2x.jpg" alt="help-header" style="width: 100%" />
						<h1 class="layout-help-page-header-text">MY ORDERS</h1>
						<div class="layout-help-page-search p-input-icon-left p-fluid shadow-2">
							<i class="pi pi-search"></i>
							<InputText type="text" placeholder="Search" />
						</div>
					</div>

					<div class="col-12 pr-5 pl-5 pb-5 pt-2">
						<div>
							<Accordion v-for="order in orders" :key="order">
								<AccordionTab>
									<template v-slot:header>
										<div class="flex align-items-center">
											<i class="pi pi-arrow-circle-down fs-large mr-2 green-color" @click="orderDownload(order.ID)" ></i>
											<i class="pi pi-trash fs-large mr-2 red-color" @click="itemDelete(order)"></i>
											<div class="flex flex-column">
												<h5 class="mb-0">{{ order.ordername }}</h5>
											</div>
										</div>
									</template>
									<Accordion class="questions" v-for="product in order.Products" :key="product">
										<AccordionTab>
											<template v-slot:header>
												<div class="flex align-items-center">
													<div class="flex flex-column">
														<h5 class="mb-0">
															{{ product.conceptid }} - 
															{{ getConceptInfo(product.conceptid) }} - 
															{{ product.year }} - 
															{{ getAgglevelInfo(product.agglevelid) }}
														</h5>
													</div>
												</div>
											</template>
										</AccordionTab>
									</Accordion>
								</AccordionTab>
							</Accordion>
						</div>
					</div>
				</div>


				<Dialog v-model:visible="deleteItemDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="deleteItem">Are you sure you want to delete <b> {{ deleteItem.ordername }}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="orderDelete(deleteItem.ID)" />
					</template>
				</Dialog>


			</div>
		</div>
	</div>

</template>

<script>

import BasketService from '../service/BasketService';
import UserService from '../service/UserService';
import { saveAs } from 'file-saver';

export default {
	data() {
		return {
			text: null,
			filteredText: [],
			orders: [],
			concepts: [],
			agglevels: [],
			deleteItemDialog: false,
			deleteItem: null,
			submitted: false			
		}
	},
	props: {
		items: Array,
	},	
	basketService: null,
	created() {
		this.basketService = new BasketService();
		this.userService = new UserService();
		if (! this.userService.checkUsersToken()) {
			let user = JSON.parse(localStorage.getItem('user'));
			if (user && user.token) {
				localStorage.removeItem('user');
			}
			this.$router.push("/login")
		}
	},
	mounted() {
		this.basketService.getOrders().then(data => {
			if (data.code == 200) {
				this.orders = data.orders;
				this.agglevels = data.agglevels;
				this.concepts = data.concepts;
				// console.log (this.orders)
			}
		});
	},	
	methods: {
		filterText(event) {
			const query = event.query;
			let filteredText = []

			for (let i = 0; i < 10; i++) {
				filteredText.push(query + i);
			}
			this.filteredText = filteredText;
		},
		getConceptInfo (conceptid) {
			var info = conceptid
			this.concepts.forEach(item => {
				if (item.code == conceptid) {
					info = item.name
				}
			});
			return info
		},
		getAgglevelInfo (conceptid) {
			var info = conceptid
			this.agglevels.forEach(item => {
				if (item.code == conceptid) {
					info = item.name
				}
			});
			return info
		},
		itemDelete(item) {
			this.deleteItemDialog = true;
			this.deleteItem = item
		},
		orderDelete(orderid) {
			this.basketService.getOrderDelete(orderid).then(data => {
				if (data.code == 200) {
					this.orders = data.orders;
					this.itemsBadgeReload(this.items, 0);
					this.deleteItemDialog = false;
					this.deleteItem = null;
				}
			});			
		},
		orderDownload(orderid) {
			this.basketService.getOrderDownload(orderid).then(data => {
				if (data.code == 200) {
					var fileName = 'myData.json';
					var fileToSave = new Blob([JSON.stringify(data.infos)], {
						type: 'application/json'
					});
					saveAs(fileToSave, fileName);
				}
			});			
		},
		itemsBadgeReload(items, level) {
			if (items && Array.isArray(items) && level < 100) {
				items.forEach( item => {
					if (item.items) {
						this.itemsBadgeReload (item.items, level+1)
					}
					if (item.type) {
						if (item.type == 'shopping') {
							this.basketService.getBasketNum().then (res => {
								item.badge = res;
							});
						} else {
							if (item.type == 'order' ) {
								this.basketService.getOrderNum().then (res => {
									item.badge = res;
								});						
							}
						}							
					}
				})
			}
		},		
	}
}
</script>

<style scoped>

</style>
